import React, { useEffect, useState } from 'react'
import style from "../styles/nabvar.module.css"


function SearchComponent({menuList, setFilteredItems}) {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(()=>{
    searchTerm === "" && setFilteredItems([...menuList])
  },[searchTerm])

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const filteredMenu = menuList.filter(item =>
      item.editorData.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredItems(filteredMenu);
  };

  return (
    <>
      <input
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleSearch}
        className={style.searchInput}
      />
    </>
  )
}

export default SearchComponent