import style from "../styles/dishesModal.module.css";
import veganIcon from "../assents/vegano.png"; // Icono de vegano
import eggIcon from "../assents/huevo.png"; // Icono de huevo
import lactoseIcon from "../assents/lacteos.png"; // Icono de lácteos
import peanutIcon from "../assents/mani.png"; // Icono de maní
import soyIcon from "../assents/soja.png"; // Icono de soja
import nutsIcon from "../assents/frutos secos.png"; // Icono de frutos secos
import cilantroIcon from "../assents/cilantro.png";
import ajiIcon from "../assents/aji.png";
import sinTacc from "../assents/sintacc.png";

function DishModal({ dish }) {
  const ingColor = {
    Vegano: { img: veganIcon, name: "Vegano" },
    Huevo: { img: eggIcon, name: "Huevo" },
    Lacteos: { img: lactoseIcon, name: "Lacteos" },
    Mani: { img: peanutIcon, name: "Mani" },
    Soja: { img: soyIcon, name: "Soja" },
    "Frutos Secos": { img: nutsIcon, name: "Frutos Secos" },
    Cilantro: { img: cilantroIcon, name: "Cilantro" },
    Picante: { img: ajiIcon, name: "Picante" },
  };
  return (
    <div className={style.body}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
        <span className={style.title}>{dish.name}</span>
        {dish.sinTacc && (
          <img
            style={{
              width: "40px",
              marginRight: "auto",
            }}
            src={sinTacc}
            alt=""
          />
        )}
      </div>
      <hr
        style={{ width: "100%", height: "2.5px", backgroundColor: "white" }}
      />
      <div className={style.container}>
        <img className={style.img} src={dish.url} alt="" />
        <div
          className={style.text}
          dangerouslySetInnerHTML={{ __html: dish.editorData }}
        ></div>
        <span>Contiene:</span>
        <div className={style.containerCircle}>
          {dish.ingredients.map((ing) => (
            <div className={style.circle}>
              <img src={ingColor[ing].img} alt="" />
              <span>{ingColor[ing].name} </span>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span className={style.price}>Precio: $ {dish.price}</span>
        </div>
      </div>
    </div>
  );
}

export default DishModal;
