import React from "react";
import Abm from "../src/pages/abm.jsx";
import LandingMenu from "../src/pages/landingMenu.jsx";
import Menu from "../src/pages/menu.jsx";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./component/Login.jsx";
import SignUp from "./component/signUp.jsx";
import Landing from "./pages/landing.jsx";

const router = createBrowserRouter([
  {
    path: "/app",
    element: <App></App>,
  },
  {
    path: "/abm",
    element: <Abm></Abm>,
  },
  {
    path: "/",
    element: <Landing></Landing>,
  },
  {
    path: "/menu",
    element: <LandingMenu></LandingMenu>,
  },
  {
    path: "/menu/:color/:collection",
    element: <Menu></Menu>,
  },
  {
    path: "/Login",
    element: <Login></Login>,
  },
  {
    path: "/register",
    element: <SignUp></SignUp>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
