import React, { useMemo } from "react";
import props from "../helpers/props.json";

function FilterAbm({ options, filterProp, activeFilters, setActiveFilters,optionTable }) {

  const sectionOp = useMemo(()=>{
    let sectionOrderList;
    if (options === props.ingredients){
      sectionOrderList = props.ingredients
    }else if (options === "menudeldia") {
      sectionOrderList = !optionTable ? props.sectionDish : props.sectionDinks;
    } else if (options === "menu" || options === "menusintacc") {
      sectionOrderList = props.sectionDish;
    } else if (options === "cafeteria") {
      sectionOrderList = props.sectionCoffe;
    } else if (options === "bebidas") {
      sectionOrderList = props.sectionDinks;
    } else if (options === "vinos") {
      sectionOrderList = props.sectionWine;
    }
return sectionOrderList
    },[options, optionTable])

  const handleSelect = (e) => {
    const value = e.target.value;
    const newFilters = { ...activeFilters, [filterProp]: value };

    if (value === "") {
      delete newFilters[filterProp];
    }

    setActiveFilters(newFilters);
  };

  return (
    <div>
      <select onChange={handleSelect}>
        <option value={""}>Ver Todo el Menu</option>
        {sectionOp.map((option, index) => (
          <option value={option} key={option + index}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FilterAbm;