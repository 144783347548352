import React from "react";
import { useEffect, useState, useMemo } from "react";
import {addDoc, setDoc} from "firebase/firestore"; 
import UseModal from "../hooks/useModal.js";
import Modal from "../component/modal.jsx";
import { doc, deleteDoc } from "firebase/firestore";
import { deleteImage } from "../helpers/helperUploadImage.js";
import DrinkAbm from "./drinckAbm.jsx";
import DrinkButton from "./drinkButton.jsx";
import style from "../styles/table.module.css";

function TableDrinks({menuCollectionRef, menu, collectionParam}) {
  const [activeDrink, setActiveDrink] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [isOpen, openModal, closeModal] = UseModal(false);
  const [newDrink, setNewDrinck] = useState(null);
  const [producState, setProducState] = useState(null);
  const [imageUpdate, setImageUpdate] = useState(null);
  const [imageUpdateIng, setImageUpdateIng] = useState(null);


  useEffect(() => {
    setActiveDrink(null);
    setNewDrinck(null);
  }, [producState]);

  const creatDrink = async (data) => {
    await addDoc(menuCollectionRef, data);
    closeModal();
    setProducState(!producState);
  };

  const UpdateDrink = async (data, id) => {
    const docRef = doc(menuCollectionRef, id);
    await setDoc(docRef, data);
    await handleDeleteImg(data);
    closeModal();
    setProducState(!producState);
  };

  const delateDrink = async (id) => {
    if (!id) {
      closeModal();
      return;
    }
    let isDelete = window.confirm("Estas Seguro que Deseas Eliminar La Bebida");
    if (isDelete) {
      await deleteDoc(doc(menuCollectionRef, id));
      await handleDeleteImg("delete");
      closeModal();
      setProducState(!producState);
    }
  };

  const handleDeleteImg = async (data) => {
    if (imageUpdate && (imageUpdate !== data.ref || data === "delete")) {
      try {
        await deleteImage(imageUpdate);
      } catch (error) {
        console.error("Error eliminando la imagen:", error);
      }
    }
    if (imageUpdateIng && (imageUpdateIng !== data.refIng || data === "delete")) {
      try {
        await deleteImage(imageUpdateIng);
      } catch (error) {
        console.error("Error eliminando la imagen:", error);
      }
    }
  };

  function openNewDrink() {
    setNewDrinck(true);
    openModal();
  }

  return (
    <div>
      {menu && (
        <div className={style.body}>
          <div className={style.btnContainer}>
            <button onClick={openNewDrink} className={style.btn}>
              Nueva Bebida +
            </button>
          </div>
          {menu.length === 0 ? (
              <h2>No Hay Bebidas Cargadas</h2>
            ) : (
          <table style={{width: "100%"}}>
            <tbody>
              {menu.map((el, index) => (
                <DrinkButton
                  drink={el}
                  openModal={openModal}
                  index={index}
                  key={el.id}
                  setActiveDrink={setActiveDrink}
                  setActiveKey={setActiveKey}
                  menuCollectionRef={menuCollectionRef}
                />
              ))}
            </tbody>
          </table>
                      )}
          <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            producState={producState}
            setProducState={setProducState}
          >
            {!newDrink ? (
              <DrinkAbm
                drink={activeDrink}
                optionModal={UpdateDrink}
                delateDrink={delateDrink}
                setImageUpdate={setImageUpdate}
                setImageUpdateIng={setImageUpdateIng}
                collectionParam={collectionParam}
                key={activeKey}
              />
            ) : (
              <DrinkAbm optionModal={creatDrink} 
              collectionParam={collectionParam}
              />
            )}
          </Modal>
        </div>
      )}
    </div>
  );
}

export default TableDrinks;
