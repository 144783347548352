import style from "../styles/cardSectionTwo.module.css";
import { useState, useMemo } from "react";
import Dishes from "./dishesTwo.jsx";
import Ingredients from "../component/ingredients";
import Drinks from "./drinksTwo.jsx";
import DrinckModal from "./drinckModal.jsx";
import sectionOrder from "../helpers/props.json";

import vasoGrande from "../assents/vaso-grande-dos.png";
import copaGin from "../assents/copon-gin-dos.png";
import copon from "../assents/copon-dos.png";
import copita from "../assents/copita-dos.png";
import vaso from "../assents/vaso-dos.png";
import largo from "../assents/largo-dos.png";
import corto from "../assents/vermut-dos.png";
import copaCoctel from "../assents/martini-dos.png";
import copa from "../assents/copa-dos.png";
import botella from "../assents/botella-dos.png";
import jarra from "../assents/jarra-dos.png";
import chico from "../assents/chico-dos.png";
import mediano from "../assents/mediano-dos.png";
import grande from "../assents/grande-dos.png";
import xxl from "../assents/xxl-dos.png";
import otro from "../assents/otros-dos.png";
import llevar from "../assents/llevar-dos.png";
import tetera from "../assents/tetera-dos.png";

import vasoD from "../assents/vaso-tres.png";
import largoD from "../assents/largo-tres.png";
import cortoD from "../assents/vermut-tres.png";
import copaCoctelD from "../assents/martini-tres.png";
import copaD from "../assents/copa-tres.png";
import botellaD from "../assents/botella-tres.png";
import jarraD from "../assents/jarra-tres.png";
import vasoGrandeD from "../assents/vaso-grande-tres.png";
import copaGinD from "../assents/copon-gin-tres.png";
import coponD from "../assents/copon-tres.png";
import copitaD from "../assents/copita-tres.png";

import copaVino from "../assents/copa-vino.png";
import botellaVino from "../assents/botella-vino.png";

import Modal from "../component/modal.jsx";
import UseModal from "../hooks/useModal.js";
import DishModal from "./dishModal.jsx";

function CardSectionTwo({ color, section, dishList }) {
  const [mostrar, setMostrar] = useState(false);
  const [activeDish, setActiveDish] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [isOpen, openModal, closeModal] = UseModal(false);
  const [producState, setProducState] = useState(null);

  const vessels = {
    "Vaso 375cc": vaso,
    "Vaso 500ml": vasoGrande,
    "Vaso Whisky": corto,
    "Vaso Trago Largo": largo,
    "Jarra 1,2 lt": jarra,
    "Copa 615cc": copaGin,
    "Copón 500ml": copon,
    "Copa Margarita": copaCoctel,
    Copita: copita,
    Copa: color === "#56070C" ? copaVino : copa,
    "269ml": color === "#56070C" ? botellaVino : botella,
    "330ml": color === "#56070C" ? botellaVino : botella,
    "355ml": color === "#56070C" ? botellaVino : botella,
    "375ml": color === "#56070C" ? botellaVino : botella,
    "473ml": color === "#56070C" ? botellaVino : botella,
    "410ml": color === "#56070C" ? botellaVino : botella,
    "500ml": color === "#56070C" ? botellaVino : botella,
    "750ml": color === "#56070C" ? botellaVino : botella,
    Chico: chico,
    Mediano: mediano,
    Grande: grande,
    "Taza XXL": xxl,
    Jarro: otro,
    "Para Llevar": llevar,
    Tetera: tetera,
    "Tetera x2": tetera,
  };

  const vesselsToday = {
    "Vaso 375cc": vasoD,
    "Vaso 500ml": vasoGrandeD,
    "Vaso Wiskhey": cortoD,
    "Vaso Trago Largo": largoD,
    "Jarra 1,2 lt": jarraD,
    "Copa 615cc": copaGinD,
    "Copón 500ml": coponD,
    "Copa Margarita": copaCoctelD,
    Copita: copitaD,
    Copa: copaD,
    "269ml": botellaD,
    "330ml": botellaD,
    "355ml": botellaD,
    "375ml": botellaD,
    "473ml": botellaD,
    "410ml": botellaD,
    "500ml": botellaD,
    "750ml": botellaD,
  };

  const groupedSection = useMemo(() => {
    // Creamos el objeto agrupado basado en los ítems filtrados
    const grouped = dishList.reduce((acc, item) => {
      if (!acc[item.subSection]) {
        acc[item.subSection] = [];
      }
      acc[item.subSection].push(item);
      return acc;
    }, {});

    // Ordenamos las secciones agrupadas basado en sectionOrderList
    const orderedGrouped = {};
    sectionOrder.subSection.forEach((section) => {
      if (grouped[section]) {
        orderedGrouped[section] = grouped[section];
      }
    });

    return orderedGrouped;
  }, [dishList]);

  return (
    <div
      type="button"
      onClick={() => setMostrar(!mostrar)}
      className={style.body}
      style={{ borderColor: color, color: color }}
    >
      <div
        className={style.sectionContainer}
        style={color === "#F0A22D" ? { color: "#A66617" } : { color: color }}
      >
        <h1>{section}</h1>
        <hr className={style.line} style={{ backgroundColor: color }} />
        <label style={{ display: mostrar ? "none" : "flex" }}>Ver Más</label>
        <div
          className={style.dishContainer}
          style={{ display: mostrar ? "flex" : "none" }}
        >
          {section === "Principales" &&
          <div>
            <p style={{textAlign: "center", fontWeight: "bold", fontSize: "12px"}}>
            TODAS NUESTRAS CARNES Y PESCADOS SON DE CALIDAD
            PREMIUM DE EXPORTACIÓN
            </p>
            {Object.keys(groupedSection).map((section) => (
              <div key={section}>
                <h1 style={{textAlign: "center", marginBottom: "10px", marginTop: "10px", fontSize: "24px"}}>{section}</h1>
                <hr className={style.line} style={{ backgroundColor: color }} />
                {groupedSection[section].map((dish) => (
                  <Dishes
                    color={"white"}
                    dish={dish}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                  ></Dishes>
                ))}
              </div>
            ))
          }
            </div>
            }

          {color === "#301E14" && section !== "Principales"
            ? dishList.map((dish) =>
                dish.price === 0 ? (
                  <Drinks
                    color={color}
                    dish={dish}
                    vessels={color === "#152b54" ? vesselsToday : vessels}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                    colorParam={color}
                  ></Drinks>
                ) : (
                  <Dishes
                    color={color}
                    dish={dish}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                  ></Dishes>
                )
              )
            : dishList.map((dish) =>
                dish.vessel ? (
                  <Drinks
                    color={color}
                    dish={dish}
                    vessels={color === "#152b54" ? vesselsToday : vessels}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                    colorParam={color}
                  ></Drinks>
                ) : (
                  section !== "Principales" && (
                    <Dishes
                      color={color}
                      dish={dish}
                      openModal={openModal}
                      setActiveDish={setActiveDish}
                      setActiveKey={setActiveKey}
                    ></Dishes>
                  )
                )
              )}
          <div style={{ display: mostrar ? "flex" : "none" }}>
            {!dishList[0].vessel && <Ingredients></Ingredients>}
            {/* {dishList.forEach(dish => (
            !dish.vessel && <Ingredients></Ingredients>)
          )} */}
          </div>
        </div>
        <label style={{ display: mostrar ? "flex" : "none" }}>Ver Menos</label>
      </div>
      {activeDish && (
        <Modal
          isOpen={isOpen}
          closeModal={false}
          producState={producState}
          setProducState={setProducState}
          onClose={() => closeModal(false)}
        >
          {color === "#301E14" ? (
            activeDish.price === 0 ? (
              <DrinckModal dish={activeDish} colorParam={color} />
            ) : (
              <DishModal dish={activeDish} />
            )
          ) : activeDish.vessel ? (
            <DrinckModal dish={activeDish} colorParam={color} />
          ) : (
            <DishModal dish={activeDish} />
          )}
        </Modal>
      )}
    </div>
  );
}

export default CardSectionTwo;
