import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../styles/MyEditor.css';

const MyEditor = ({ editorData, setEditorData }) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={editorData}
            config={{
                placeholder: 'Escriba Aquí la descripción del Nuevo Plato',
                toolbar: ['bold', 'italic', 'link', 'undo', 'redo'],
                link: {
                    decorators: {
                        addTargetToExternalLinks: {
                            mode: 'automatic',
                            callback: url => url.startsWith('http'),
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        }
                    }
                }
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                setEditorData(data);
            }}
        />
    );
};

export default MyEditor;
