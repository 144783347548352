import React, { useState, useEffect } from 'react';
import veganIcon from "../assents/vegano.png";
import eggIcon from "../assents/huevo.png";
import lactoseIcon from "../assents/lacteos.png";
import peanutIcon from "../assents/mani.png";
import soyIcon from "../assents/soja.png";
import nutsIcon from "../assents/frutos secos.png";
import cilantroIcon from "../assents/cilantro.png";
import ajiIcon from "../assents/aji.png";
import style from "../styles/filterComponent.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function FilterComponent({ menuList, setFilteredItems, desplegado, color }) {
  const [activeIcons, setActiveIcons] = useState({
    Vegano: false,
    Huevo: false,
    Lacteos: false,
    Mani: false,
    Soja: false,
    FrutosSecos: false,
    Cilantro: false,
    Picante: false 
  });

  const [activeIngredients, setActiveIngredients] = useState({
    include: [],
    exclude: []
  });

  const toggleIconState = (ingredient, isInclude) => {
    let ingredients = "";
    if (ingredient === "Frutos Secos") {
      ingredients = "FrutosSecos";
    } else {
      ingredients = ingredient;
    }
    setActiveIcons((prevState) => ({
      ...prevState,
      [ingredients]: !prevState[ingredients],
    }));

    setActiveIngredients(prevState => {
      const updatedInclude = isInclude
        ? prevState.include.includes(ingredient)
          ? prevState.include.filter(item => item !== ingredient)
          : [...prevState.include, ingredient]
        : prevState.include;

      const updatedExclude = !isInclude
        ? prevState.exclude.includes(ingredient)
          ? prevState.exclude.filter(item => item !== ingredient)
          : [...prevState.exclude, ingredient]
        : prevState.exclude;

      return {
        include: updatedInclude,
        exclude: updatedExclude
      };
    });
  };

  useEffect(() => {
    filterMenu();
  }, [activeIngredients]);

  const filterMenu = () => {
    if (activeIngredients.include.length === 0 && activeIngredients.exclude.length === 0) {
      setFilteredItems(menuList);
      return;
    }

    let filtered = menuList;

    if (activeIngredients.include.length > 0) {
      filtered = filtered.filter(item =>
        !item.ingredients || activeIngredients.include.every(ingredient =>
          item.ingredients.includes(ingredient)
        )
      );
    }

    if (activeIngredients.exclude.length > 0) {
      filtered = filtered.filter(item =>
        !item.ingredients || activeIngredients.exclude.every(ingredient =>
          !item.ingredients.includes(ingredient)
        )
      );
    }

    setFilteredItems(filtered);
  };

  return (
    <div
      className={style.filterComponent}
      style={{ display: desplegado ? "block" : "none", backgroundColor: color }}
    >
      <div>
        <div style={{ display: "flex", gap: "10px" }} className={style.iconContainer}>
          <img
            src={veganIcon}
            alt="Vegano"
            className={`${style.filterIcon} ${style.vegano}`}
            onClick={() => toggleIconState('Vegano', true)}
            active={activeIcons.Vegano.toString()}
          />
          <span>Vegano</span>
        </div>
        <div style={{ display: "flex", gap: "10px" }} className={style.iconContainer}>
          <img
            style={{ position: "absolute", zIndex: "1" }}
            src={eggIcon}
            alt="Huevo"
            className={style.filterIcon}
            onClick={() => toggleIconState("Huevo", false)}
            active={activeIcons.Huevo.toString()}
          />
          {!activeIcons.Huevo ? (
            <VisibilityIcon
              className={style.filterIcontwo}
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          ) : (
            <VisibilityOffIcon
              className={style.filterIcontwo}
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          )}
          <span>Huevo</span>
        </div>
        <div style={{ display: "flex", gap: "10px" }} className={style.iconContainer}>
          <img
            style={{ position: "absolute", zIndex: "1" }}
            src={lactoseIcon}
            alt="Lacteos"
            className={style.filterIcon}
            onClick={() => toggleIconState("Lacteos", false)}
            active={activeIcons.Lacteos.toString()}
          />
          {!activeIcons.Lacteos ? (
            <VisibilityIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          ) : (
            <VisibilityOffIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          )}
          <span>Lacteos</span>
        </div>
        <div style={{ display: "flex", gap: "10px" }} className={style.iconContainer}>
          <img
            style={{ position: "absolute", zIndex: "1" }}
            src={peanutIcon}
            alt="Mani"
            className={style.filterIcon}
            onClick={() => toggleIconState("Mani", false)}
            active={activeIcons.Mani.toString()}
          />
          {!activeIcons.Mani ? (
            <VisibilityIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          ) : (
            <VisibilityOffIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          )}
          <span>Maní</span>
        </div>
        <div style={{ display: "flex", gap: "10px" }} className={style.iconContainer}>
          <img
            style={{ position: "absolute", zIndex: "1" }}
            src={soyIcon}
            alt="Soja"
            className={style.filterIcon}
            onClick={() => toggleIconState("Soja", false)}
            active={activeIcons.Soja.toString()}
          />
          {!activeIcons.Soja ? (
            <VisibilityIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          ) : (
            <VisibilityOffIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          )}
          <span>Soja</span>
        </div>
        <div style={{ display: "flex", gap: "10px", textAlign: "center" }} className={style.iconContainer}>
          <img
            style={{ position: "absolute", zIndex: "1" }}
            src={nutsIcon}
            alt="Frutos Secos"
            className={style.filterIcon}
            onClick={() => toggleIconState("Frutos Secos", false)}
            active={activeIcons.FrutosSecos.toString()}
          />
          {!activeIcons.FrutosSecos ? (
            <VisibilityIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          ) : (
            <VisibilityOffIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          )}
          <span>Frutos Secos</span>
        </div>
        <div style={{ display: "flex", gap: "10px" }} className={style.iconContainer}>
          <img
            style={{ position: "absolute", zIndex: "1" }}
            src={cilantroIcon}
            alt="Cilantro"
            className={style.filterIcon}
            onClick={() => toggleIconState("Cilantro", false)}
            active={activeIcons.Cilantro.toString()}
          />
          {!activeIcons.Cilantro ? (
            <VisibilityIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          ) : (
            <VisibilityOffIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          )}
          <span>Cilantro</span>
        </div>
        <div style={{ display: "flex", gap: "10px" }} className={style.iconContainer}>
          <img
            style={{ position: "absolute", zIndex: "1" }}
            src={ajiIcon}
            alt="Picante"
            className={style.filterIcon}
            onClick={() => toggleIconState("Picante", false)}
            active={activeIcons.Picante.toString()}
          />
          {!activeIcons.Picante ? (
            <VisibilityIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          ) : (
            <VisibilityOffIcon
              style={{
                position: "relative",
                top: "-10",
                right: "-30",
                zIndex: "2",
              }}
            />
          )}
          <span>Picante</span>
        </div>
      </div>
    </div>
  );
}

export default FilterComponent;
