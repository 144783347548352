import style from "../styles/dishesAbm.module.css";
import MyEditor from "./myEditor.jsx";
import props from "../helpers/props.json";
import { useEffect, useState, useMemo } from "react";
import ImageUploader from "./imageUpload.jsx";

function DrinkAbm({
  drink,
  optionModal,
  delateDrink,
  setImageUpdate,
  setImageUpdateIng,
  collectionParam,
}) {
  const [name, setName] = useState(drink ? drink.name : "");
  const [sectionDinks, setSectionDinks] = useState(
    drink ? drink.section : false
  );
  const [editorData, setEditorData] = useState(drink ? drink.editorData : "");
  const [imageDish, setImageDish] = useState(
    drink ? { ref: drink.ref, url: drink.url } : { ref: "", url: "" }
  );
  const [imageIng, setImageIng] = useState(
    drink ? { ref: drink.refIng, url: drink.urlIng } : { ref: "", url: "" }
  );
  const [vessels, setVessels] = useState(
    drink
      ? drink.vessel
      : {
          "Vaso 375cc" : { checked: false, price: 0 },
          "Vaso 500ml" : { checked: false, price: 0 },
          "Vaso Wiskhey" : { checked: false, price: 0 },
          "Vaso Trago Largo" : { checked: false, price: 0 },
          "Jarra 1,2 lt" : { checked: false, price: 0 },
          "Copa 615cc" : { checked: false, price: 0 },
          "Copón 500ml" : { checked: false, price: 0 },
          "Copa Margarita" : { checked: false, price: 0 },
          "Copita" : { checked: false, price: 0 },
          "Copa" : { checked: false, price: 0 },
          "269ml" : { checked: false, price: 0 },
          "330ml" : { checked: false, price: 0 },
          "355ml" : { checked: false, price: 0 },
          "375ml" : { checked: false, price: 0 },
          "473ml" : { checked: false, price: 0 },
          "410ml" : { checked: false, price: 0 },
          "500ml" : { checked: false, price: 0 },
          "750ml" : { checked: false, price: 0 }
        }
  );
  useEffect(() => {
    drink && setImageUpdate(imageDish.ref);
    drink && setImageUpdateIng(imageIng.ref);
  }, [drink]);

  const section = useMemo(()=>{
    const sectionProp = collectionParam === "vinos" ? props.sectionWine : props.sectionDinks
    return sectionProp
    },[collectionParam])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !sectionDinks ||
      !hasValidVessel(vessels) ||
      !imageDish.url
    ) {
      alert("Por favor, complete todos los campos y cargue las imágenes.");
      return;
    }

    const newForm = {
      name: name,
      section: sectionDinks,
      vessel: vessels,
      url: imageDish.url,
      ref: imageDish.ref,
      urlIng: imageIng.url,
      refIng: imageIng.ref,
      editorData: editorData,
      stock: true,
    };

    if (drink) {
      await optionModal(newForm, drink.id);
    } else {
      await optionModal(newForm, false);
    }

    alert("El plato se ha cargado correctamente");
  };

  const handleVessel = (e, vesselName) => {
    const vessel = { ...vessels[vesselName] };
    if (e.target.name === "checked") {
      vessel[e.target.name] = e.target.checked;
    } else {
      vessel[e.target.name] = e.target.value;
    }
    if (vessel.checked === true) {
      setVessels({
        ...vessels,
        [vesselName]: vessel,
      });
    } else {
      setVessels({
        ...vessels,
        [vesselName]: {
          checked: false,
          price: 0,
        },
      });
    }
  };

  function hasValidVessel(vessels) {
    return Object.values(vessels).some(
      (vessel) => vessel.checked && vessel.price > 0
    );
  }

  return (
    <>
      <form className={style.modal} onSubmit={handleSubmit} style={{width: "800px"}}>
        <div style={{ display: "flex" }}>
          <div>
            <div className={style.containerUno}>
              <div className={style.title}>
                <span htmlFor="name">Nombre : </span>
                <input
                  type="text"
                  value={name}
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Nombre de la Bebida"}
                />
              </div>
            </div>
            <div className={style.containerDos}>
              <span>Sección:</span>
              <select
                name="section"
                onChange={(e) => setSectionDinks(e.target.value)}
                defaultValue={sectionDinks}
                style={{ width: "85%" }}
              >
                <option value={false}>Elija Una Seccion del Menu</option>
                {section && section.map((el, index) => (
                      <option key={index} value={el}>
                        {el}
                      </option>
                    ))}
              </select>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              ></div>
            </div>
            <div
              className={style.containerCuatro}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "210px",
                gap: "10px"
              }}
            >
              <span style={{ marginRight: "0" }}>Recipiente</span>
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      height: "190px",
                      width: "600px",
                      flexDirection: "column",
                      justifyItems: "center",
                    }}
                  >
                    {props.vessels.map((el, index) => (
                      <div key={el + index} style={{ display: "flex", gap: "2px"}}>
                        <label>{el}</label>
                        <label htmlFor="checked"></label>
                        <input
                          type="checkbox"
                          id="checked"
                          name="checked"
                          value={vessels[el].checked}
                          defaultChecked={vessels[el].checked}
                          onChange={(e) => handleVessel(e, el)}
                          style={{ marginLeft: "auto" }}
                        />
                        <div className={style.price}>
                          <label>Precio : $ </label>
                          <label htmlFor="price"></label>
                          <input
                            type="number"
                            value={vessels[el].price}
                            id="price"
                            name="price"
                            style={{width: "70px", marginRight: "10px"}}
                            onChange={(e) => handleVessel(e, el)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={style.containerCinco}
            style={{ flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <span style={{ marginRight: "0" }}>Bebida</span>
              <ImageUploader
                img={imageDish}
                setProps={setImageDish}
                collection={"bebidas"}
                subCollection={"bebida"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <span style={{ marginRight: "0" }}>Ingredientes</span>
              <ImageUploader
                img={imageIng}
                setProps={setImageIng}
                collection={"bebidas"}
                subCollection={"ingredientes"}
              />
            </div>
          </div>
        </div>
        <div className={style.containerSeis} style={{ overflow: "scroll" }}>
          <span>Descripcion del Plato</span>
          <MyEditor editorData={editorData} setEditorData={setEditorData} />
        </div>
        <div className={style.btnContainer} style={{ marginTop: "10px" }}>
          <div
            className={style.deleteBtn}
            style={{ marginRight: "auto", marginLeft: "5px", padding: "5px" }}
          >
            <input
              type="button"
              id="delete"
              onClick={() => delateDrink(drink && drink.id)}
              style={{ display: "none" }}
            />
            <label htmlFor="delete">Borrar</label>
          </div>
          <div
            className={style.checkBtn}
            style={{ marginLeft: "auto", marginRight: "5px", padding: "5px" }}
          >
            <input type="submit" id="save" style={{ display: "none" }} />
            <label htmlFor="save">Guardar</label>
          </div>
        </div>
      </form>
    </>
  );
}

export default DrinkAbm;
