import style from "../styles/dishesButton.module.css";
import SwitchStock from "./switchStock.jsx";
import { doc} from "firebase/firestore";
import EditNoteIcon from '@mui/icons-material/EditNote';





function DrinkButton({ drink, openModal, setActiveDrink, setActiveKey, index, menuCollectionRef}) {

  function openDrink( ) {
    openModal();
    setActiveDrink(drink);
    setActiveKey(index)
  }

  const docRef = doc(menuCollectionRef, drink.id);
  
  return (
    <>
    <tr className={style.dishContainer}>
    <td style={{width: "52px"}}>
      <EditNoteIcon type="button" className={style.btnDishes} style={{fontSize: "40"}} onClick={openDrink}></EditNoteIcon>
      </td>
      <td style={{width: "210px"}}>
        <span>{drink.name}</span>
      </td>
      <td style={{width: "180px"}}>
      <span>{drink.section}</span>
      </td>
      <td style={{width: "240px"}}>
      {drink.vessel && Object.keys(drink.vessel).map((vessel, index) => (
          drink.vessel[vessel].checked && 
          <span key={vessel + index}>{vessel} $ {drink.vessel[vessel].price} </span>
        ))}
      </td>
      <td style={{width: "122px"}}>
      <SwitchStock id={index} stock={drink.stock} docRef={docRef}></SwitchStock>
      </td>
    </tr>
    </>
  );
}

export default DrinkButton;
