import React from "react";
import { useEffect, useState } from "react";
import { addDoc, setDoc, doc, deleteDoc } from "firebase/firestore";
import DishesAbm from "../component/dishesAbm.jsx"; // Importa el componente DishesAbm
import UseModal from "../hooks/useModal.js"; // Importa el hook personalizado UseModal
import Modal from "../component/modal.jsx"; // Importa el componente Modal
import DishesButton from "../component/dishesButton.jsx"; // Importa el componente DishesButton
import { deleteImage } from "../helpers/helperUploadImage.js"; // Importa la función deleteImage
import style from "../styles/table.module.css";

function TableDish({ menuCollectionRef, menu }) {
  const [activeDish, setActiveDish] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [isOpen, openModal, closeModal] = UseModal(false);
  const [newProduct, setNewProduct] = useState(null);
  const [producState, setProducState] = useState(null);
  const [imageUpdate, setImageUpdate] = useState(null);
  const [imageUpdateIng, setImageUpdateIng] = useState(null);

  // useEffect para reiniciar el estado de plato activo y nuevo producto cuando el estado del producto cambie
  useEffect(() => {
    setActiveDish(null);
    setNewProduct(null);
  }, [producState]);

  // Función para crear un nuevo plato
  const creatDish = async (data) => {
    await addDoc(menuCollectionRef, data);
    setProducState(!producState);
    closeModal();
  };

  // Función para actualizar un plato existente
  const UpdateDish = async (data, id) => {
    const docRef = doc(menuCollectionRef, id);
    await setDoc(docRef, data);
    await handleDeleteImg(data);
    setProducState(!producState);
    closeModal();
  };

  // Función para eliminar un plato
  const delateDish = async (id) => {
    if (!id) {
      closeModal();
      return;
    }
    let isDelete = window.confirm(
      "¿Estás seguro que deseas eliminar el plato?"
    );
    if (isDelete) {
      await deleteDoc(doc(menuCollectionRef, id));
      await handleDeleteImg("delete");
      setProducState(!producState);
      closeModal();
    }
  };

  // Función para manejar la eliminación de imágenes
  const handleDeleteImg = async (data) => {
    if (imageUpdate && (imageUpdate !== data.ref || data === "delete")) {
      try {
        await deleteImage(imageUpdate);
      } catch (error) {
        console.error("Error eliminando la imagen:", error);
      }
    }
    if (
      imageUpdateIng &&
      (imageUpdateIng !== data.refIng || data === "delete")
    ) {
      try {
        await deleteImage(imageUpdateIng);
      } catch (error) {
        console.error("Error eliminando la imagen:", error);
      }
    }
  };

  // Función para abrir el modal de nuevo plato
  function openNewDish() {
    setNewProduct(true);
    openModal();
  }

  return (
    <div>
      {menu && (
        <div className={style.body}>
          <div className={style.btnContainer}>
            <button onClick={openNewDish} className={style.btn}>
              Nuevo Plato +
            </button>
          </div>
          {menu.length === 0 ? (
            <h2>No Hay Platos Cargados</h2>
          ) : (
            <table>
              <tbody>
                {menu.map((el, index) => (
                  <DishesButton
                    dish={el}
                    openModal={openModal}
                    index={index}
                    key={el.id}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                    menuCollectionRef={menuCollectionRef}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
      <div className={style.modal}>
        <Modal
          isOpen={isOpen}
          closeModal={closeModal}
          producState={producState}
          setProducState={setProducState}
        >
          {!newProduct ? (
            <DishesAbm
              dish={activeDish}
              optionModal={UpdateDish}
              delateDish={delateDish}
              setImageUpdate={setImageUpdate}
              setImageUpdateIng={setImageUpdateIng}
              key={activeKey}
            />
          ) : (
            <DishesAbm optionModal={creatDish} />
          )}
        </Modal>
      </div>
    </div>
  );
}

export default TableDish; // Exporta el componente TableDish como exportación predeterminada
