import style from '../styles/buttonsFooter.module.css';
import { Link } from "react-router-dom";  // Importa el componente Link de react-router-dom para la navegación

function ButtonsMenus({color}) {
  return (
    <div className={style.btnContainer}>
        <div style={{marginLeft: "auto", width: "50%"}}>
          <Link to="/menu/274526/menu">
          <div type="button" className={style.btnLeft} style={{backgroundColor: "#274526"}}>
              <h2>Menu</h2>
          </div>
          </Link>
          </div>
        <div style={{marginRight: "auto", width: "50%"}}>
          <Link to="/menu/AA1803/menusintacc">
          <div type="button" className={style.btnRigth} style={{backgroundColor: "#AA1803"}}>
              <h2>Menu Sin Tacc</h2>
          </div>
          </Link>
          </div>
    </div>
  )
}

export default ButtonsMenus