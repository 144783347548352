import { useEffect, useRef } from "react";
import "../styles/modal.css";  // Importa los estilos personalizados para el modal
import CloseIcon from "@mui/icons-material/Close";  // Importa el icono de cierre desde Material UI

const Modal = ({ children, isOpen, closeModal, producState, setProducState, onClose}) => {
  // Función para manejar el cierre del modal
  const handleCloseModal = () => {
    setProducState(!producState);  // Alterna el estado de producState
    closeModal(!isOpen);  // Cierra el modal
  };
  
  const componentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    // Contenedor principal del modal, con una clase que se aplica si el modal está abierto
    <div className={`modal ${isOpen && "isOpen"}`} onClick={onClose}>
      <div className="modal-container">
        {closeModal && <div className="modal-close">
          <CloseIcon
            onClick={handleCloseModal}  // Maneja el cierre del modal al hacer clic en el icono
            style={{
              fontSize: 30,  // Tamaño del icono
              marginRight: 10,  // Margen derecho del icono
              marginTop: -15,  // Margen superior negativo del icono
              color: "#076633"  // Color del icono
            }}
          />
        </div>}
        {children}
      </div>
    </div>
  );
};

export default Modal;  // Exporta el componente Modal como exportación predeterminada
