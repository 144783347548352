import React, { useState } from 'react'


function SearchComponent({setMenuList, menu}) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const filteredMenu = menu.filter(item =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setMenuList(filteredMenu);
  };

  return (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Buscar por nombre"
      />
    </div>
  );
}


export default SearchComponent