import React, { useEffect, useState } from "react";
import style from "../styles/table.module.css";

import { db } from "../config-firebase";
import SwitchStock from "./switchStock.jsx";
import { onSnapshot, doc } from "firebase/firestore";

import TableDish from "../component/tableDish.jsx";
import TableDrinks from "../component/tableDrinks.jsx";

function TableTodayMenu({ menuCollectionRef, menu, setOptionTable}) {
  const [menuDish, setMenuDish] = useState([]);
  const [menuDrink, setMenuDrink] = useState([]);
  const [tableOpen, setTableOpen] = useState(false);
  const [statusTodayMenu, setStatusTodayMenu] = useState(null);

  useEffect(() => {
    setMenuDish(menu.filter((item) => item.ingredients));
    setMenuDrink(menu.filter((item) => item.vessel));
  }, [menu]);

  const refDoc = doc(db, "menudeldiaActive", "menudeldiaStatus");

  useEffect(() => {
    const getCollection = onSnapshot(refDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setStatusTodayMenu(docSnapshot.data());
      } else {
        console.log("No such document!");
      }
    });

    return () => getCollection();
  }, [refDoc]);

  const handleNewDish = () => {
    setTableOpen(false);
    setOptionTable(false)
  };

  const handleNewDrink = () => {
    setTableOpen(true);
    setOptionTable(true)
  };

  return (
    <div>
      {menu && (
        <div className={style.body}>
          <div className={style.btnContainer}>
            <button onClick={handleNewDish} className={style.btn}>
              Ver Platos
            </button>
            <button onClick={handleNewDrink} className={style.btn}>
              Ver Bebidas
            </button>
            {statusTodayMenu && (
              <SwitchStock
                id={"todayMenu"}
                stock={statusTodayMenu.stock}
                docRef={refDoc}
              ></SwitchStock>
            )}
          </div>
          <div className={style.tableContainer}>
            {tableOpen ? (
              <TableDrinks
                menuCollectionRef={menuCollectionRef}
                menu={menuDrink}
              />
            ) : (
              <TableDish
                menuCollectionRef={menuCollectionRef}
                menu={menuDish}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default TableTodayMenu;
