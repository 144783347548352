import React, { useEffect, useState } from 'react'
import { getDoc, updateDoc } from '@firebase/firestore';
import '../styles/switchStock.css'

function SwitchStock({ id, stock, docRef }) {
  const [isStock, setActiveStock] = useState(stock);

  useEffect(() => {
    handleStock().then();
  }, [isStock]);

  const handleStock = async () => {
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          stock: isStock
        });
      } else {
        console.error(`No document found: ${docRef.path}`);
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  return (
    <div>
      <div className='switch-button' style={{margin: "5px"}}>
        <span>
          {isStock ? "Disponible" : "Agotado"}
        </span>
        <input
          type="checkbox"
          checked={isStock}
          onChange={() => setActiveStock(!isStock)}
          name="switch-button"
          id={id}
          className='switch-button__checkbox'
        />
        <label htmlFor={id} className='switch-button__label'></label>
      </div>
    </div>
  );
}

export default SwitchStock;