import React, { useState, useRef } from "react";
import { uploadImage} from "../helpers/helperUploadImage";
import style from "../styles/imageUpload.module.css";
import SaveAsIcon from '@mui/icons-material/SaveAs';

const ImageUploader = ({ img, setProps, collection, subCollection}) => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(img ? img.url : null);

  const uploadInputRef = useRef(null);

  const callFileControl = () => {
    uploadInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      if (image) {
        const data = await uploadImage(image, `images/${collection}/${subCollection}`);
        setProps(data);
        alert("Imagen subida Correctamente");
      }
    } catch (error) {
      console.error("Error subiendo imagen:", error);
    }
  };

  return (
    <div style={{ display: "flex"}}>
      <div
        onClick={callFileControl}
        style={{
          position: "relative",
          width: "100px",
          height: "100px",
          borderStyle: "solid",
          border: "1px solid #076633",
          borderRadius: "5px",
        }}
      >
        {preview && (
          <div>
            <img src={preview} alt="Preview" width="100" height="100" />
          </div>
        )}
      </div>
      <input
        ref={uploadInputRef}
        type="file"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <div>
      <SaveAsIcon onClick={handleUpload} className={style.checkBtn}/>
      </div>
    </div>
  );
};

export default ImageUploader;
