import React, { useState } from 'react';
import { updateDoc, doc } from "firebase/firestore";
import style from '../styles/abmstyles.module.css';

function UpdatePrices({ menuList, setMenuList, menuCollectionRef }) {
  const [percentage, setPercentage] = useState('');

  const handleUpdatePrices = async () => {
    if (!percentage) {
      alert("Por favor, ingrese un porcentaje válido.");
      return;
    }

    const updatedMenuList = menuList.map(item => {
      
      const newItem = {
        ...item,
        price: Math.round(Number(item.price) + Number((item.price * (percentage / 100))))
      };

      const itemDoc = doc(menuCollectionRef, item.id);
      updateDoc(itemDoc, { price: newItem.price });
      setPercentage("")
      return newItem;
    });

    setMenuList(updatedMenuList);
    alert("Los precios se han actualizado correctamente.");
  };

  return (
    <div className={style.updatePricesContainer}>
      <input
        type="number"
        value={percentage}
        onChange={(e) => setPercentage(e.target.value)}
        placeholder="Porcentaje de aumento"
        className={style.updatePricesInput}
      />
      <button onClick={handleUpdatePrices} className={style.updatePricesButton}>
        Actualizar Precios
      </button>
    </div>
  );
}

export default UpdatePrices;