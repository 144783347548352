import style from "../styles/dishes.module.css";
import sinTaccIcon from "../assents/sintacc.png";

const ingColor = {
  "Vegano": "076633",
  "Huevo": "F9B233",
  "Lacteos": "0F70B7",
  "Mani": "706F6F",
  "Soja": "A2185B",
  "Frutos Secos": "E94E1A",
  "Cilantro": "94C11F",
  "Picante"  : "c6262d"
};

function Dishes({ color, dish, openModal, setActiveDish, setActiveKey }) {
  function openDish() {
    openModal();
    setActiveDish(dish);
    setActiveKey(dish.id);
  }

  return (
    <div className={style.body} onClick={openDish}>
      <div className={style.container}>
        <div className={style.titleContainerTwo}>
          <span className={style.titleTwo}>{dish.name}</span>
        </div>
        <div style={{width: "29vw", height: "15vh", position: "relative", padding:"5px"}}>
        <img
          className={style.img}
          style={{ borderBlockColor: color.color }}
          src={dish.url}
          alt=""
        />
        {dish.sinTacc &&
                <img
                style={{ width: "50px", position: "absolute", zIndex: "2", left: "-10px", bottom: "0"}}
                src={sinTaccIcon}
                alt=""
              />
        }
        </div>
      </div>
      <div className={style.containerDos}>
        <div
          className={style.text}
          dangerouslySetInnerHTML={{ __html: dish.editorData }}
        ></div>
        <div className={style.containerCircle}>
          {dish.ingredients && dish.ingredients.map((ing) => (
            <div
              className={style.circle}
              style={{ backgroundColor: `#${ingColor[ing]}` }}
            ></div>
          ))}
          <span className={style.price}>${dish.price}</span>
        </div>
      </div>
    </div>
  );
}

export default Dishes;
