import style from "../styles/ingredients.module.css"
import veganIcon from "../assents/vegano.png";  // Icono de vegano
import eggIcon from "../assents/huevo.png";  // Icono de huevo
import lactoseIcon from "../assents/lacteos.png";  // Icono de lácteos
import peanutIcon from "../assents/mani.png";  // Icono de maní
import soyIcon from "../assents/soja.png";  // Icono de soja
import nutsIcon from "../assents/frutos secos.png";  // Icono de frutos secos
import cilantroIcon from "../assents/cilantro.png";  // Icono de cilantro
import ajiIcon from "../assents/aji.png";



function Ingredients() {
    return (
    <div className={style.body}>
        <div className={style.conteiner}>
        <img className={style.img} src={veganIcon} alt="" />
        <p>Vegano</p>
    </div>
    <div className={style.conteiner}>
    <img className={style.img} src={nutsIcon} alt="" />
        <p>Frutos Secos</p>
    </div>
    <div className={style.conteiner}>
    <img className={style.img} src={soyIcon} alt="" />
        <p>Soja</p>
    </div>
    <div className={style.conteiner}>
    <img className={style.img} src={cilantroIcon} alt="" />
        <p>Cilantro</p>
    </div>
    <div className={style.conteiner}>
    <img className={style.img} src={lactoseIcon} alt="" />
        <p>Lacteos</p>
    </div>
    <div className={style.conteiner}>
    <img className={style.img} src={peanutIcon} alt="" />
        <p>Mani</p>
    </div>
    <div className={style.conteiner}>
    <img className={style.img} src={eggIcon} alt="" />
        <p>Huevo</p>
    </div>
    <div className={style.conteiner}>
    <img className={style.img} src={ajiIcon} alt="" />
        <p>Picante</p>
    </div>
  </div>
  )
}

export default Ingredients
