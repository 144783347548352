import style from "../styles/abmstyles.module.css";
import { useEffect, useState } from "react";
import { collection } from "firebase/firestore";
import { db } from "../config-firebase";
import { onSnapshot } from "firebase/firestore";
import Loader from "../component/loader.jsx";
import TableDish from "../component/tableDish.jsx";
import TableDrinks from "../component/tableDrinks.jsx";
import FilterAbm from "../component/filterAbm.jsx";
import props from "../helpers/props.json";
import SearchComponent from "../component/searchComponentAbm.jsx";
import UpdatePrices from "../component/porcentualPrice.jsx";
import UpdatePricesDrinck from "../component/porcentualPriceDrinck.jsx";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../config-firebase.js";
import { useNavigate } from "react-router-dom";
import TableTodayMenu from "../component/tableTodayMenu.jsx";
import TableMenuAlter from "../component/tableMenuAlter.jsx";


function Abm() {
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collectionParam, setCollectionParam] = useState("menu");
  const [optionTable, setOptionTable] = useState(false);
  const [menuTitle, setMenuTitle] = useState("Menu");
  const [menuList, setMenuList] = useState([]);
  const [activeFilters, setActiveFilters] = useState({});
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false)

  const navigate = useNavigate();
  // Referencia a la colección en Firestore basada en el parámetro de la colección actual
  const menuCollectionRef = collection(db, collectionParam);
  const adminEmails = ["puducoop@gmail.com"]


  useEffect(() => {
    setOptionTable(false)
  }, [collectionParam])

  useEffect(() => {
    setLoading(true);
    const getCollection = onSnapshot(menuCollectionRef, (snapshot) => {
      const menuData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        collection: collectionParam,
      }));
      setMenu(menuData);
      setMenuList(menuData);
      setLoading(false);
    });
    return () => getCollection();
  }, [collectionParam]);

  useEffect(() => {
    let filteredMenu = [...menu];

    Object.keys(activeFilters).forEach((filterProp) => {
      if (activeFilters[filterProp]) {
        filteredMenu = filteredMenu.filter((item) => item[filterProp] && item[filterProp].includes(activeFilters[filterProp]));
      }
    });

    setMenuList(filteredMenu);
  }, [activeFilters, menu]);

  //recibe y chequea los datos del user (si hay)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        if (adminEmails.includes(user.email)) {
          setIsAdmin(true);
        }
      } else {
        navigate("/login");
      }
    });
  }, []);
  // Maneja el cambio de la tabla y del parámetro de la colección
  const handleTable = (params, title) => {
    setCollectionParam(params);
    setMenuTitle(title);
  };

  const handleClearFilters = () => {
    setActiveFilters({});
    setMenuList(menu);
  };

  //maneja el logout
  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    })
  }

  const goToregister = () => {
    navigate('/register')
  }


  return (
    <div className={style.abm}>
      <div className={style.title}>
      <div className={style.userRol}>
        {isAdmin && (<button className={style.btnLogout} onClick={goToregister}>Crear usuario</button>)}
        <button className={style.btnLogout} onClick={handleLogout}>Cerrar sesión</button>
        <span >Usuario: {user && (user.displayName || user.email)}</span>
      </div>
      <div className={style.titleOne}>
        <h1>{menuTitle}</h1>
        <h2>Carga de platos</h2>
      </div>
      </div>
      <div className={style.buttonsContainer}>
        <button
          className={style.btnMenus}
          onClick={() => handleTable("menu", "Menu")}
        >
          Menú
        </button>
        <button
          className={style.btnMenus}
          onClick={() => handleTable("menusintacc", "Menu Sin TACC")}
        >
          Menú Sin TACC
        </button>
        <button
          className={style.btnMenus}
          onClick={() => handleTable("cafeteria", "Cafeteria")}
        >
          Cafeteria
        </button>
        <button
          className={style.btnMenus}
          onClick={() => handleTable("bebidas", "Bebidas")}
        >
          Bebidas
        </button>
        <button
          className={style.btnMenus}
          onClick={() => handleTable("vinos", "Vinos")}
        >
          Vinos
        </button>
        <button
          className={style.btnMenus}
          onClick={() => handleTable("menudeldia", "Menu del Dia")}
        >
          Menú del Día
        </button>
      </div>
      {loading && (
        <div className={style.loaderErrorCoint}>
          <Loader />
        </div>
      )}
      {(collectionParam === "menu" || collectionParam === "menusintacc") && (
        <div className={style.tableContainer}>
          <TableDish menuCollectionRef={menuCollectionRef} menu={menuList} />
        </div>
      )}
      {collectionParam === "cafeteria" && (
        <div className={style.tableContainer}>
          <TableMenuAlter
            menuCollectionRef={menuCollectionRef}
            menu={menuList}
          />
        </div>
      )}
      {(collectionParam === "bebidas" || collectionParam === "vinos") && (
        <div className={style.tableContainer}>
          <TableDrinks
            menuCollectionRef={menuCollectionRef}
            menu={menuList}
            collectionParam={collectionParam}
          />
        </div>
      )}
      {collectionParam === "menudeldia" && (
        <div className={style.tableContainer}>
          <TableTodayMenu
            menuCollectionRef={menuCollectionRef}
            menu={menuList}
            setOptionTable={setOptionTable}
          />
        </div>
      )}
      <div className={style.optionGrupContainer}>
        <div>
          <h4>Buscar por Nombre:</h4>
          <SearchComponent setMenuList={setMenuList} menu={menu} />
        </div>
        <div>
          <h4>Aumentar Porcentaje de Platos</h4>
          <UpdatePrices
            setMenuList={setMenuList}
            menuList={menuList}
            menuCollectionRef={menuCollectionRef}
          />
        </div>

        <div>
          <h4>Aumentar Porcentaje de Bebidas</h4>
          <UpdatePricesDrinck
            setMenuList={setMenuList}
            menuList={menuList}
            menuCollectionRef={menuCollectionRef}
          />
        </div>
        <div>
          <h4>Filtro por Ingredientes</h4>
          <FilterAbm
            options={props.ingredients}
            setMenuList={setMenuList}
            menu={menu}
            menuList={menuList}
            filterProp={"ingredients"}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        </div>
        <div>
          <h4>Filtro por Sección</h4>
          <FilterAbm
            optionTable={optionTable}
            options={collectionParam}
            setMenuList={setMenuList}
            menu={menu}
            menuList={menuList}
            filterProp={"section"}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        </div>
        <button
          onClick={handleClearFilters}
          className={style.clearFiltersButton}
        >
          Limpiar Filtros
        </button>
      </div>
    </div>
  );
}

export default Abm;
