import style from '../styles/buttonsFooter.module.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";  // Importa el componente Link de react-router-dom para la navegación

function ButtonsDrincksWine({color}) {
  return (
    <div className={style.btnContainer}>
        <div style={{marginLeft: "auto", width: "50%"}}>
        <Link to="/menu/F0A22D/bebidas">
            <div type="button" className={style.btnLeft} style={{backgroundColor: "#F0A22D"}}>
              <h2>Bebidas</h2>
          </div>
          </Link>
        </div>
        <div style={{marginRight: "auto", width: "50%"}}>
        <Link to="/menu/56070C/vinos">
            <div type="button" className={style.btnRigth} style={{backgroundColor: "#56070C"}}>
              <h2>Vinos</h2>
          </div>
          </Link>
        </div>
    </div>
  )
}

export default ButtonsDrincksWine