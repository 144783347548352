import style from "../styles/dishesAbm.module.css";
import MyEditor from "./myEditor.jsx";
import props from "../helpers/props.json";
import UseCheck from "../hooks/useCheck";
import { useState, useEffect } from "react";
import ImageUploader from "./imageUpload.jsx";

function DishesAbm({
  dish,
  optionModal,
  delateDish,
  setImageUpdate,
  setImageUpdateIng,
}) {
  const [name, setName] = useState(dish ? dish.name : "");
  const [price, setPrice] = useState(dish ? dish.price : 0);
  const [section, setSection] = useState(dish ? dish.section : false);
  const [subSection, setSubSection] = useState((dish && dish.subSeaction) ? dish.subSection : false);
  const [ingredients, addIngredietn, deletIngredient] = UseCheck(
    dish ? dish.ingredients : []
  );
  const [editorData, setEditorData] = useState(dish ? dish.editorData : "");
  const [imageDish, setImageDish] = useState(
    dish ? { ref: dish.ref, url: dish.url } : { ref: "", url: "" }
  );
  const [imageIng, setImageIng] = useState(
    dish ? { ref: dish.refIng, url: dish.urlIng } : { ref: "", url: "" }
  );

  useEffect(()=>{
    dish && setImageUpdate(imageDish.ref);
    dish && setImageUpdateIng(imageIng.ref);
  }, [dish])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !price ||
      !section ||
      !ingredients.length ||
      !editorData ||
      !imageDish.url
    ) {
      alert("Por favor, complete todos los campos y cargue las imágenes.");
      return;
    }

    const newForm = {
      name: name,
      price: price,
      section: section,
      subSection: subSection,
      ingredients: ingredients,
      url: imageDish.url,
      ref: imageDish.ref,
      urlIng: imageIng.url,
      refIng: imageIng.ref,
      editorData: editorData,
      stock: true,
    };

    if (dish) {
      setImageUpdate(imageDish.ref);
      setImageUpdateIng(imageIng.ref);
      await optionModal(newForm, dish.id);
    } else {
      await optionModal(newForm, false);
    }

    alert("El plato se ha cargado correctamente");
    
  };

  const handleIngredients = (e) => {
    e.target.checked
      ? addIngredietn(e.target.value)
      : deletIngredient(e.target.value);
  };

  const verifyCheckDefault = (list, item) => list.includes(item);

  return (
    <>
      <form className={style.modal} onSubmit={handleSubmit}>
      <div style={{display: "flex"}}>
        <div>
        <div className={style.containerUno}>
          <div className={style.title}>
            <span>Nombre : </span>
            <input
              type="text"
              value={name}
              id="name"
              onChange={(e) => setName(e.target.value)}
              placeholder={"Nombre del Plato"}
            />
          </div>
          <div className={style.price}>
            <span>Precio : $ </span>
            <label htmlFor="price"></label>
            <input
              type="number"
              value={price}
              id="price"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
        <div className={style.containerDos} style={{flexDirection: "column"}}>
          <span>Sección:</span>
          <select
            name="section"
            onChange={(e) => setSection(e.target.value)}
            defaultValue={section}
            style={{ width: "85%" }}
          >
            <option value={false}>Elija Una Seccion del Menu</option>
            {props.sectionDish.map((el, index) => (
              <option key={el + index} value={el}>
                {el}
              </option>
            ))}
          </select>
          {section === "Principales" &&
          <div>
                              <span>Sub-Sección:</span>
                              <select
                                name="section"
                                onChange={(e) => setSubSection(e.target.value)}
                                defaultValue={subSection}
                                style={{ width: "85%" }}
                              >
                                <option value={null}>General</option>
                                {props.subSection.map((el, index) => (
                                  <option key={el + index} value={el}>
                                    {el}
                                  </option>
                                ))}
                              </select>
          </div>}
        </div>
        <div className={style.containerCuatro}  style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <span style={{marginRight: "10px"}}>Ingredientes:</span>
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
            {props.ingredients.map((el, index) => (
              <div
                key={"div" + el + index}
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                <label htmlFor={el} key={"label" + el + index}>{el}</label>
                <input
                key={"input" + el + index}
                  type="checkbox"
                  id={el}
                  name="ingredients"
                  value={el}
                  onChange={handleIngredients}
                  defaultChecked={verifyCheckDefault(ingredients, el)}
                />
              </div>
            ))}
          </div>
        </div>
        </div>
        <div className={style.containerCinco}>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center", width: "50%"}}>
            <span style={{marginRight: "0"}}>Plato</span>
            <ImageUploader
              img={imageDish}
              setProps={setImageDish}
              collection = {"platos"}
              subCollection={"plato"}
            />
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center", width: "50%"}}>
            <span style={{marginRight: "0"}}>Ingredientes</span>
            <ImageUploader
              img={imageIng}
              setProps={setImageIng}
              collection = {"platos"}
              subCollection={"ingredientes"}
            />
          </div>
        </div>
        </div>
        <div className={style.containerSeis} style={{overflow: "scroll"}}>
          <span>Descripcion del Plato</span>
          <MyEditor editorData={editorData} setEditorData={setEditorData} />
        </div>
        <div className={style.btnContainer} style={{marginTop: "10px"}}>
          <div className={style.deleteBtn} style={{marginRight: "auto", marginLeft: "5px", padding:"5px"}}>
            <input
              type="button"
              id="delete"
              onClick={() => delateDish(dish && dish.id)}
              style={{ display: "none" }}
            />
            <label htmlFor="delete">Borrar</label>
          </div>
          <div className={style.checkBtn} style={{marginLeft: "auto", marginRight: "5px", padding: "5px"}}>
            <input type="submit" id="save" style={{ display: "none" }} />
            <label htmlFor="save">Guardar</label>
          </div>
        </div>
      </form>
    </>
  );
}

export default DishesAbm;
