import style from "../styles/cardSection.module.css";
import { useState, useMemo} from "react";
import Dishes from "./dishes.jsx";
import Ingredients from "../component/ingredients";
import Drinks from "./drinks.jsx";
import sectionOrder from "../helpers/props.json";

import vasoGrande from "../assents/vaso-grande.png";
import copaGin from "../assents/copon-gin.png";
import copon from "../assents/copon.png";
import copita from "../assents/copita.png";
import vaso from "../assents/vaso.png";
import largo from "../assents/largo.png";
import corto from "../assents/vermut.png";
import copaCoctel from "../assents/martini.png";
import copa from "../assents/copa.png";
import botella from "../assents/botella.png";
import jarra from "../assents/jarra.png";
import chico from "../assents/chico.png";
import mediano from "../assents/mediano.png";
import grande from "../assents/grande.png";
import xxl from "../assents/xxl.png";
import otro from "../assents/otros.png";
import llevar from "../assents/llevar.png";
import tetera from "../assents/tetera.png";

import Modal from "../component/modal.jsx"; // Importa el componente Modal
import UseModal from "../hooks/useModal.js"; // Importa el hook personalizado UseModal
import DishModal from "./dishModal.jsx";
import DrinckModal from "./drinckModal.jsx";

function CardSection({ color, section, dishList }) {
  const [mostrar, setMostrar] = useState(false);

  const [activeDish, setActiveDish] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [isOpen, openModal, closeModal] = UseModal(false);
  const [producState, setProducState] = useState(null);

  const vessels = {
    "Vaso 375cc": vaso,
    "Vaso 500ml": vasoGrande,
    "Vaso Wiskhey": corto,
    "Vaso Trago Largo": largo,
    "Jarra 1,2 lt": jarra,
    "Copa 615cc": copaGin,
    "Copón 500ml": copon,
    "Copa Margarita": copaCoctel,
    Copita: copita,
    Copa: copa,
    "269ml": botella,
    "330ml": botella,
    "355ml": botella,
    "375ml": botella,
    "473ml": botella,
    "410ml": botella,
    "500ml": botella,
    "750ml": botella,
    Chico: chico,
    Mediano: mediano,
    Grande: grande,
    "Taza XXL": xxl,
    Jarro: otro,
    "Para Llevar": llevar,
    Tetera: tetera,
    "Tetera x2": tetera,
  };

  const groupedSection = useMemo(() => {
    // Creamos el objeto agrupado basado en los ítems filtrados
    const grouped = dishList.reduce((acc, item) => {
      if (!acc[item.subSection]) {
        acc[item.subSection] = [];
      }
      acc[item.subSection].push(item);
      return acc;
    }, {});

    // Ordenamos las secciones agrupadas basado en sectionOrderList
    const orderedGrouped = {};
    sectionOrder.subSection.forEach((section) => {
      if (grouped[section]) {
        orderedGrouped[section] = grouped[section];
      }
    });

    return orderedGrouped;
  }, [dishList]);

  return (
    <div
      type="button"
      onClick={() => setMostrar(!mostrar)}
      className={style.body}
      style={{ backgroundColor: color }}
    >
      <div className={style.sectionContainer}>
        <h1 style={{ marginLeft: "auto" }}>{section}</h1>
        <hr className={style.line} />
        <label
          style={{ display: mostrar ? "none" : "flex", marginLeft: "auto" }}
        >
          Ver Más
        </label>
        <div
          className={style.dishContainer}
          style={{ display: mostrar ? "flex" : "none" }}
        >
          {section === "Principales" &&
          <div>
            <p style={{textAlign: "center", fontWeight: "bold", fontSize: "12px"}}>
            TODAS NUESTRAS CARNES Y PESCADOS SON DE CALIDAD
            PREMIUM DE EXPORTACIÓN
            </p>
            {Object.keys(groupedSection).map((section) => (
              <div key={section}>
                <h1 style={{textAlign: "center", marginBottom: "10px", marginTop: "10px", fontSize: "24px"}}>{section}</h1>
                <hr className={style.line} style={{ backgroundColor: color }} />
                {groupedSection[section].map((dish) => (
                  <Dishes
                    color={"white"}
                    dish={dish}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                  ></Dishes>
                ))}
              </div>
            ))
          }
            </div>
            }
          {color === "#301E14" && section !== "Principales"
            ? dishList.map((dish) =>
                dish.price === 0 ? (
                  <Drinks
                    color={"white"}
                    dish={dish}
                    vessels={vessels}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                    colorParam={color}
                  ></Drinks>
                ) : (
                  <Dishes
                    color={"white"}
                    dish={dish}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                  ></Dishes>
                )
              )
              
            : dishList.map((dish) =>
                dish.vessel ? (
                  <Drinks
                    color={"white"}
                    dish={dish}
                    vessels={vessels}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                    colorParam={color}
                  ></Drinks>
                ) : (
                  section !== "Principales" &&
                  <Dishes
                    color={"white"}
                    dish={dish}
                    openModal={openModal}
                    setActiveDish={setActiveDish}
                    setActiveKey={setActiveKey}
                  ></Dishes>
                )
              )}
        </div>
        <div style={{ display: mostrar ? "flex" : "none" }}>
          {!dishList[0].vessel && <Ingredients></Ingredients>}
        </div>
      </div>
      <label style={{ display: mostrar ? "flex" : "none", marginLeft: "auto" }}>
        Ver Menos
      </label>
      {activeDish && (
        <Modal
          isOpen={isOpen}
          closeModal={false}
          producState={producState}
          setProducState={setProducState}
          onClose={() => closeModal(false)}
        >
          {color === "#301E14" ? (
            activeDish.price === 0 ? (
              <DrinckModal dish={activeDish} colorParam={color} />
            ) : (
              <DishModal dish={activeDish} />
            )
          ) : activeDish.vessel ? (
            <DrinckModal dish={activeDish} colorParam={color} />
          ) : (
            <DishModal dish={activeDish} />
          )}
        </Modal>
      )}
    </div>
  );
}

export default CardSection;
