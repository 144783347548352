import style from "../styles/landingMenu.module.css";  // Importa los estilos específicos para este componente
import { useState, useEffect, useMemo } from 'react';  // Importa los hooks useState, useEffect y useMemo de React
import { Link } from "react-router-dom";  // Importa el componente Link de react-router-dom para la navegación
import logo from '../assents/logo-negro.png';

import { db } from "../config-firebase";
import { onSnapshot, doc } from "firebase/firestore";

function Landing() {
  const [statusTodayMenu, setStatusTodayMenu] = useState(null);

  // Memoiza la referencia al documento para evitar su recreación en cada renderizado
  const refDoc = useMemo(() => doc(db, "menudeldiaActive", "menudeldiaStatus"), []);

  useEffect(() => {
    const unsubscribe = onSnapshot(refDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setStatusTodayMenu(docSnapshot.data());
      } else {
        console.log("No such document!");
      }
    });

    return () => unsubscribe();
  }, [refDoc]);

  // Estado para controlar la visibilidad de los botones del menú
  const [mostrar, setMostrar] = useState(
    window.sessionStorage.getItem("butonsMenu") === 'true' // Obtiene el valor inicial del estado desde sessionStorage
  );

  // Función para actualizar el estado y almacenar el valor en sessionStorage
  const setLocalStorage = value => {
    setMostrar(value);
    window.sessionStorage.setItem("butonsMenu", value);
  }

  return (
    <div className={style.background}>
      <div className={style.content}>
          <div className={style.main} style={mostrar ? {display:"none"} : {display:"flex"}}>
              <img className={style.logo} src={logo} alt="logo chimi deli"/>
              <h2 className={style.text}>La inclusión y la diversidad son los mejores condimentos</h2>
              <button className={style.btnOpen} onClick={() => setLocalStorage(true)}>Ver Menú</button>
          </div>
          <div className={style.show} style={!mostrar ? {display:"none"} : {display:"flex"}}>
            {(statusTodayMenu && statusTodayMenu.stock) &&
              <Link to="/menu/152b54/menudeldia" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{width: "15px", height: "15px", borderRadius: "15px", marginRight: "10px", backgroundColor:"#152b54" }}></div>
                  <div type="button" style={{ color: "#152b54" }}>
                    <h1>Menú del Día</h1>
                  </div>
              </Link>}
            <Link to="/menu/274526/menu" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "15px", height: "15px", borderRadius: "15px", marginRight: "10px", backgroundColor:"#274526" }}></div>
              <div type="button" style={{ color: "#274526" }}>
                  <h1>Menú</h1>
              </div>
            </Link>
            <Link to="/menu/AA1803/menusintacc" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "15px", height: "15px", borderRadius: "15px", marginRight: "10px", backgroundColor:"#AA1803" }}></div>
              <div type="button" style={{ color: "#AA1803" }}>
                  <h1>Menú Sin Tacc</h1>
              </div>
            </Link>
            <Link to="/menu/301E14/cafeteria" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "15px", height: "15px", borderRadius: "15px", marginRight: "10px", backgroundColor:"#301E14" }}></div>
              <div type="button" style={{ color: "#301E14" }}>
                  <h1>Cafeteria</h1>
              </div>
            </Link>
            <Link to="/menu/F0A22D/bebidas" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "15px", height: "15px", borderRadius: "15px", marginRight: "10px", backgroundColor:"#F0A22D" }}></div>
              <div type="button" style={{ color: "#F0A22D" }}>
                  <h1>Bebidas</h1>
              </div>
            </Link>
            <Link to="/menu/56070C/vinos" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "15px", height: "15px", borderRadius: "15px", marginRight: "10px", backgroundColor:"#56070C" }}></div>
              <div type="button" style={{ color: "#56070C" }}>
                  <h1>Vinos</h1>
              </div>
            </Link>
          </div>
      </div>
    </div>
  );
}

export default Landing;
