import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../config-firebase.js";

// Función para subir una imagen a Firebase Storage
const uploadImage = (image, path) => {
    return new Promise((resolve, reject) => {
        if (!image) {
            reject("No image provided");
            return;
        }

        const storageRef = ref(storage, `${path}/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
            },
            (error) => {
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    resolve({ url, ref: `${path}/${image.name}` });
                }).catch(reject);
            }
        );
    });
};

const deleteImage = (refPath) => {
    return new Promise((resolve, reject) => {
        if (!refPath) {
            reject("No reference path provided");
            return;
        }
        const imageRef = ref(storage, refPath);
        deleteObject(imageRef)
            .then(() => {
                resolve(`Image with reference ${refPath} deleted successfully`);
            })
            .catch(reject);
    });
};

const updateImage = async (refPath, newImage, path) => {
    await deleteImage(refPath);
    return uploadImage(newImage, path);
};

export { uploadImage, deleteImage, updateImage };