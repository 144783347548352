import SwitchStock from "./switchStock.jsx";
import PriceUpdate from "./priceUpdate.jsx";
import style from "../styles/dishesButton.module.css";
import { doc} from "firebase/firestore";
import EditNoteIcon from '@mui/icons-material/EditNote';


function DishesButton({ dish, openModal, setActiveDish, setActiveKey, index, menuCollectionRef}) {

  function openDish( ) {
    openModal();
    setActiveDish(dish);
    setActiveKey(index)
  }

  const docRef = doc(menuCollectionRef, dish.id);

  return (
    <tr className={style.dishContainer} >
      <td style={{width: "52px"}}>
      <EditNoteIcon type="button" className={style.btnDishes} style={{fontSize: "40"}} onClick={openDish}></EditNoteIcon>
      </td>
      <td style={{width: "200px"}}>
      <span>{dish.name}</span>
      </td>
      <td style={{width: "120px"}}>
      <span>{dish.section}</span>
      </td>
      <td style={{width: "120px"}}>
        {dish.ingredients && dish.ingredients.map((ingredient)=>(
          <span key={ingredient + index}>{ingredient} </span>
        ))}
      </td>      
      <td style={{width: "160px"}}>
        <PriceUpdate price={dish.price} docRef={docRef}></PriceUpdate>
      </td>
      <td style={{width: "122px"}}>
        <SwitchStock id={index} stock={dish.stock} docRef={docRef}></SwitchStock>
      </td>
    </tr>
  );
}

export default DishesButton;
