import React, { useState } from 'react';
import loginstyle from "../styles/loginstyle.css";  // Importa los estilos personalizados para el componente de inicio de sesión
import { auth } from "../config-firebase";  // Importa la configuración de Firebase
import { GoogleAuthProvider, signInWithPopup, signOut, signInWithEmailAndPassword } from 'firebase/auth';  // Importa los métodos de autenticación de Firebase
import { useNavigate } from 'react-router-dom';  // Importa el hook de navegación de React Router

function Login() {
    const [loading, setLoading] = useState(false);  // Estado para manejar el estado de carga
    const [error, setError] = useState(null);  // Estado para manejar los errores de inicio de sesión
    const navigate = useNavigate();  // Hook de navegación para redirigir al usuario después del inicio de sesión
    const allowedEmails = ["puducoop@gmail.com", "chimidelinatural@gmail.com", "admideli1@gmail.com"]; // Lista de correos electrónicos permitidos

    // Función para manejar el clic en el botón de inicio de sesión con Google
    async function handleOnClick() {
        const googleProvider = new GoogleAuthProvider();  // Crea una instancia del proveedor de autenticación de Google
        await signInWithGoogle(googleProvider);  // Llama a la función de inicio de sesión con Google
    }

    // Función para iniciar sesión con Google
    async function signInWithGoogle(googleProvider) {
        setLoading(true);  // Establece el estado de carga en verdadero
        setError(null);  // Resetea el estado de error
        try {
            const res = await signInWithPopup(auth, googleProvider);  // Realiza el inicio de sesión con popup
            const email = res.user.email;  // Obtiene el correo electrónico del usuario autenticado

            // Verifica si el correo electrónico está en la lista de correos permitidos
            if (allowedEmails.includes(email)) {
                navigate('/abm');  // Redirige al usuario a la ruta '/abm' si el correo está permitido
            } else {
                await signOut(auth);  // Cierra la sesión si el correo no está permitido
                alert("Acceso denegado: Este correo electrónico no está autorizado.");  // Muestra una alerta indicando acceso denegado
            }
        } catch (error) {
            setError("Error al iniciar sesión: " + error.message);  // Establece el estado de error con el mensaje de error
        } finally {
            setLoading(false);  // Establece el estado de carga en falso
        }
    }

    //funcion para inicio de sesion con usuario y contraseña
    //credenciales de prueba: prueba@gmail.com clave prueba
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const loginHandler = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                navigate("/abm");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert("Error al iniciar sesion. Revise sus credenciales e intente de nuevo.")
                console.log(errorCode, errorMessage)
            })
    }

    return (
        <div className="Style">
            <div className='login-container'>
                <div className="login-box">
                    <h1>CHIMI <span>DELI</span></h1>
                    <h2>COCINA NATURAL</h2>
                    <h3>Correo Electronico</h3>
                    <input className="login-input" type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                    <h3>Contraseña</h3>
                    <input className="login-input" type='password' placeholder='Contraseña' onChange={(e) => setPassword(e.target.value)} />
                    <div className='login-btn-container'>
                        <button type='submit' className='login-btn' onClick={loginHandler}>Iniciar Sesion</button>
                    </div>
                    <div className='google-btn-container'>
                        <button onClick={handleOnClick} className='login-btn' disabled={loading}>
                            {loading ? <div className="spinner"></div> : "Iniciar Sesion con Google"}
                        </button>
                    </div>
                </div>
            </div>

            {error && <p className="error">{error}</p>}
        </div>
    );
}

export default Login;  // Exporta el componente Login como exportación predeterminada

