import React from 'react'
import "../styles/flowerloader.css"
import floresDerecha from '../assents/flores-loader-derecha.png'
import floresIzquierda from '../assents/flores-loader-izquierda.png'
function FlowerLoader() {
  return (
    <div className='flower-father-container'>
        <div className='flower-container'>
            <img src={floresIzquierda} className='flores-izquierda' alt='flores rojas y azules, ilustrativas'></img>
            <img src={floresDerecha} className='flores-derecha' alt='flores verdes y amarillas, ilustrativas'></img>
        </div>
        <div className='loader-container'>
        <h3 className='cargando-text'>Cargando</h3>
        <div><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
        </div>
        
   </div>
  )
}

export default FlowerLoader