import style from '../styles/priceUpdate.module.css'
import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react';
import {updateDoc} from "firebase/firestore";


function PriceUpdate({price, docRef}) {
  const [isNewPrice,setNewPrice] = useState(price)
useEffect(()=>{setNewPrice(price)},[price])
  

  const handlePrice = async () => {
    await updateDoc(docRef, {
      price: isNewPrice
    });
  }

  return (
    <div>
        <div className={style.price}>
            <span>Precio: $</span>
            <input type="number" value={isNewPrice} onChange={(e) => setNewPrice(e.target.value)}/>
            <CheckIcon style={{color: "white", fontSize: "20", backgroundColor: "#076633", borderColor: "#076633", marginLeft: "5px"}} onClick={() => handlePrice().then()}></CheckIcon>
        </div>            
    </div>
  )
}

export default PriceUpdate