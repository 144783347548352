import style from "../styles/dishes.module.css";
import { useMemo } from "react";
import props from "../helpers/props.json";
import sinTaccIcon from "../assents/sintacc.png";

function Drinks({
  color,
  dish,
  vessels,
  openModal,
  setActiveDish,
  setActiveKey,
  colorParam,
}) {
  function openDish() {
    openModal();
    setActiveDish(dish);
    setActiveKey(dish.id);
  }

  const sortedVessels = useMemo(() => {
    if (colorParam !== "#301E14") {
      const order = Object.keys(dish.vessel)
        .filter((vessel) => dish.vessel[vessel].checked)
        .sort((a, b) => props.vessels.indexOf(a) - props.vessels.indexOf(b));
      return order;
    } else {
      const order = Object.keys(dish.vessel)
        .filter((vessel) => dish.vessel[vessel].checked)
        .sort((a, b) => props.measures.indexOf(a) - props.measures.indexOf(b));
      return order;
    }
  }, [colorParam]);

  return (
    <div className={style.body} onClick={openDish}>
      <div className={style.container}>
        <div className={style.titleContainerTwo}>
          <span className={style.titleTwo}>{dish.name}</span>
        </div>
      <div style={{width: "29vw", height: "15vh", position: "relative", padding:"5px"}}>
        
        <img
          className={style.img}
          style={{ borderBlockColor: color.color }}
          src={dish.url}
          alt=""
        />
        {dish.sinTacc && (
          <img
            style={{
              width: "50px",
              position: "absolute",
              zIndex: "1",
              left: "-10px",
              bottom: "0",
            }}
            src={sinTaccIcon}
            alt=""
          />
        )}
        </div>
      </div>
      <div className={style.containerDos}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className={style.text}
            dangerouslySetInnerHTML={{ __html: dish.editorData }}
          ></div>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px"}}>
            {dish.vessel && sortedVessels.map(
                  (vessel) =>
                    dish.vessel[vessel].checked && (
                      <div>
                        <img src={vessels[vessel]} style={{ height: "20px", marginRight: "10px"}} />
                        <span className={style.price} >{vessel}: $ {dish.vessel[vessel].price}</span>
                      </div>
                    )
                )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drinks;
