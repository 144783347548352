import { useState } from "react";

const UseCheck = (initialValue = []) => {
  const [isCheck, setIsCheck] = useState(initialValue);

  const addOption = (el) => {
    const newOption = el;
    setIsCheck([...isCheck, newOption]);
  };

  const deletOption = (el) => {
    const newOption = isCheck.filter((ing) => ing !== el);
    
    setIsCheck(newOption);
  };

  return [isCheck, addOption, deletOption, setIsCheck];
};

export default UseCheck;
