import style from "../styles/dishesAbm.module.css";
import MyEditor from "./myEditor.jsx";
import props from "../helpers/props.json";
import UseCheck from "../hooks/useCheck.js";
import { useEffect, useState } from "react";
import ImageUploader from "./imageUpload.jsx";

function AlterAbm({
  drink,
  optionModal,
  delateDrink,
  setImageUpdate,
  setImageUpdateIng,
}) {
  const [name, setName] = useState(drink ? drink.name : "");
  const [sectionDinks, setSectionDinks] = useState(
    drink ? drink.section : false
  );
  const [sinTacc, setSinTacc] = useState(drink ? drink.sinTacc : false);
  const [editorData, setEditorData] = useState(drink ? drink.editorData : "");
  const [price, setPrice] = useState(drink ? drink.price : 0);
  const [imageDish, setImageDish] = useState(
    drink ? { ref: drink.ref, url: drink.url } : { ref: "", url: "" }
  );
  const [imageIng, setImageIng] = useState(
    drink ? { ref: drink.refIng, url: drink.urlIng } : { ref: "", url: "" }
  );
  const [vessels, setVessels] = useState(
    drink
      ? drink.vessel
      : {
          Chico: { checked: false, price: 0 },
          Mediano: { checked: false, price: 0 },
          Grande: { checked: false, price: 0 },
          "Taza XXL": { checked: false, price: 0 },
          Jarro: { checked: false, price: 0 },
          "Para Llevar": { checked: false, price: 0 },
          Tetera: { checked: false, price: 0 },
          "Tetera x2": { checked: false, price: 0 },
        }
  );
  const [ingredients, addIngredietn, deletIngredient] = UseCheck(
    drink ? drink.ingredients : []
  );
  const [drinkState, setDrinkState] = useState(drink ? drink.drinkState : false);

  useEffect(() => {
    drink && setImageUpdate(imageDish.ref);
    drink && setImageUpdateIng(imageIng.ref);
  }, [drink]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !sectionDinks || !imageDish.url) {
      alert("Por favor, complete todos los campos y cargue las imágenes.");
      return;
    }

    const newForm = {
      name: name,
      price: price,
      section: sectionDinks,
      vessel: vessels,
      url: imageDish.url,
      ref: imageDish.ref,
      urlIng: imageIng.url,
      refIng: imageIng.ref,
      editorData: editorData,
      ingredients: ingredients,
      sinTacc: sinTacc,
      drinkState: drinkState,
      stock: true,
    };

    if (drink) {
      await optionModal(newForm, drink.id);
    } else {
      await optionModal(newForm, false);
    }

    alert("El plato se ha cargado correctamente");
  };

  const handleVessel = (e, vesselName) => {
    const vessel = { ...vessels[vesselName] };
    if (e.target.name === "checked") {
      vessel[e.target.name] = e.target.checked;
    } else {
      vessel[e.target.name] = e.target.value;
    }
    if (vessel.checked === true) {
      setVessels({
        ...vessels,
        [vesselName]: vessel,
      });
    } else {
      setVessels({
        ...vessels,
        [vesselName]: {
          checked: false,
          price: 0,
        },
      });
    }
  };

  const handleIngredients = (e) => {
    e.target.checked
      ? addIngredietn(e.target.value)
      : deletIngredient(e.target.value);
  };
  const handleSinTacc = (e) => {
    setSinTacc(e.target.checked);
  };

  const verifyCheckDefault = (list, item) => list.includes(item);

  return (
    <>
      <form className={style.modal} onSubmit={handleSubmit}>
        <div style={{ display: "flex" }}>
          <div>
          <div className={style.btnContainerTwo}>
        <div
            className={style.checkBtn}
            style={{marginRight: "5px", padding: "5px" }}
          >
            <input type="button" id="Cafe/Te" style={{ display: "none" }} onClick={() => setDrinkState(true)}/>
            <label htmlFor="Cafe/Te">Cafe/Te</label>
        </div>
        <div
            className={style.checkBtn}
            style={{marginRight: "5px", padding: "5px", width: "11rem"}}
          >
            <input type="button" id="Pasteleria" style={{ display: "none" }} onClick={() => setDrinkState(false)}/>
            <label htmlFor="Pasteleria">Pasteleria/Desayunos</label>
        </div>
      </div>
            <div className={style.containerUno} style={{ display: "flex" }}>
              <div>
                <div className={style.title}>
                  <span htmlFor="name">Nombre : </span>
                  <input
                    type="text"
                    value={name}
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    placeholder={"Nombre de la Bebida"}
                  />
                </div>
                {!drinkState &&
                <div className={style.price}>
                  <span>Precio : $ </span>
                  <label htmlFor="price"></label>
                  <input
                    type="number"
                    value={price}
                    id="price"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
}
              </div>
              <div style={{ marginLeft: "auto" }}>
                <label htmlFor={"Sin Tacc"} style={{ fontWeight: "bold" }}>
                  Sin Tacc
                </label>
                <input
                  type="checkbox"
                  id={"Sin Tacc"}
                  name="Sin Tacc"
                  value={sinTacc}
                  onChange={handleSinTacc}
                  defaultChecked={sinTacc}
                />
              </div>
            </div>
            <div className={style.containerDos}>
              <span>Sección:</span>
              <select
                name="section"
                onChange={(e) => setSectionDinks(e.target.value)}
                defaultValue={sectionDinks}
                style={{ width: "85%" }}
              >
                <option value={false}>Elija Una Seccion del Menu</option>
                {props.sectionCoffe.map((el, index) => (
                  <option key={index} value={el}>
                    {el}
                  </option>
                ))}
              </select>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              ></div>
            </div>
            {!drinkState &&
            <div className={style.containerTres}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "100px"
                }}
              >
                <span style={{ marginRight: "10px" }}>Ingredientes:</span>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {props.ingredients.map((el, index) => (
                    <div
                      key={"div" + el + index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px",
                      }}
                    >
                      <label htmlFor={el} key={"label" + el + index}>
                        {el}
                      </label>
                      <input
                        key={"input" + el + index}
                        type="checkbox"
                        id={el}
                        name="ingredients"
                        value={el}
                        onChange={handleIngredients}
                        defaultChecked={verifyCheckDefault(ingredients, el)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>}
            {drinkState &&
            <div className={style.containerCuatro}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "0" }}>Recipiente</span>
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      height: "100px",
                      width: "550px",
                      flexDirection: "column",
                      justifyItems: "center",
                    }}
                  >
                    {props.measures.map((el, index) => (
                      <div key={el + index} style={{ display: "flex" }}>
                        <label>{el}</label>
                        <label htmlFor="checked"></label>
                        <input
                          type="checkbox"
                          id="checked"
                          name="checked"
                          value={vessels[el].checked}
                          defaultChecked={vessels[el].checked}
                          onChange={(e) => handleVessel(e, el)}
                          style={{ marginLeft: "auto"}}
                        />
                        <div className={style.price}>
                          <label>Precio : $ </label>
                          <label htmlFor="price"></label>
                          <input
                            type="number"
                            value={vessels[el].price}
                            id="price"
                            name="price"
                            onChange={(e) => handleVessel(e, el)}
                            style={{width: "70px", marginRight: "10px"}}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>}
          </div>
          <div className={style.containerCinco}
            style={{ flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <span style={{ marginRight: "0" }}>Plato/Bebida</span>
              <ImageUploader
                img={imageDish}
                setProps={setImageDish}
                collection={"cafeteria"}
                subCollection={"plato"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <span style={{ marginRight: "0" }}>Ingredientes</span>
              <ImageUploader
                img={imageIng}
                setProps={setImageIng}
                collection={"cafeteria"}
                subCollection={"ingredientes"}
              />
            </div>
          </div>
        </div>
        <div className={style.containerSeis} style={{ overflow: "scroll"}}>
          <span>Descripcion del Plato</span>
          <MyEditor editorData={editorData} setEditorData={setEditorData} />
        </div>
        <div className={style.btnContainer} style={{ marginTop: "10px"}}>
          <div
            className={style.deleteBtn}
            style={{ marginRight: "auto", marginLeft: "5px", padding: "5px" }}
          >
            <input
              type="button"
              id="delete"
              onClick={() => delateDrink(drink && drink.id)}
              style={{ display: "none" }}
            />
            <label htmlFor="delete">Borrar</label>
          </div>
          <div
            className={style.checkBtn}
            style={{ marginLeft: "auto", marginRight: "5px", padding: "5px" }}
          >
            <input type="submit" id="save" style={{ display: "none" }} />
            <label htmlFor="save">Guardar</label>
          </div>
        </div>
      </form>
    </>
  );
}

export default AlterAbm;
