import style from "../styles/footer.module.css";
import face from "../assents/Facebook.png";
import insta from "../assents/instagram.webp"
import whatsapp from "../assents/WhatsApp.webp";

function Footer() {
  return (
    <div className={style.body}>
      <div className={style.containContacts}>
        <div>
          <div>
            <h4 className={style.text}>Encuéntranos en:</h4>
          </div>
          <div>
            <a href="https://www.google.com/maps/place/Chimi+Deli+Cocina+Natural/@-41.1356443,-71.3093777,15z/data=!4m6!3m5!1s0x961a7b3281fdc791:0x63e280aa9e300a9c!8m2!3d-41.1356443!4d-71.3093777!16s%2Fg%2F11qnswmzqc?entry=ttu" className={style.text} target="_blank">
              Dirección: Ada María Elflein 54, R8400 San Carlos de Bariloche, Río Negro</a>
          </div>
        </div>
        <div>
          <h4 className={style.text}>Contáctanos en:</h4>
          <div className={style.contain}>
            <a href="https://www.instagram.com/chimideli/?hl=es" className={style.text} target="_blank">Instagram</a>
            <img className={style.img} src={insta} alt="icono de instagram" />
          </div>
          <div className={style.contain}>
            <a href="https://www.facebook.com/chimidelinatural/" className={style.text} target="_blank">Facebook</a>
            <img className={style.imgdos} src={face} alt="icono de facebook" />
          </div>
          <div className={style.contain}>
            <a href="https://api.whatsapp.com/send?phone=5492944684242&text=Informaci%C3%B3n" className={style.text} target="_blank">+5492944684242</a>
            <img className={style.img} src={whatsapp} alt="icono de whatsapp" />
          </div>
        </div>
      </div>
      <hr style={{ width: "80%", height: "1px", backgroundColor: "white" }} />

      <div className={style.containInfor}>
        <p>Desarrollado por <a href="https://linkedin.com/company/pudu-coop" style={{ color: "white", textDecoration: "underline" }}>PuduCoop</a></p>
        <p>Derechos de autor y Marca Registrada Chimi Deli</p>
      </div>
    </div>
  )
}

export default Footer
