import style from "../styles/dishesModal.module.css";
import sinTaccIcon from "../assents/sintacc.png";
import props from "../helpers/props.json";
import { useMemo } from "react";



function DrinckModal({ dish, colorParam }) {
  const sortedVessels = useMemo(() => {
    if (colorParam !== "#301E14") {
      const order = Object.keys(dish.vessel)
        .filter((vessel) => dish.vessel[vessel].checked)
        .sort((a, b) => props.vessels.indexOf(a) - props.vessels.indexOf(b));
      return order;
    } else {
      const order = Object.keys(dish.vessel)
        .filter((vessel) => dish.vessel[vessel].checked)
        .sort((a, b) => props.measures.indexOf(a) - props.measures.indexOf(b));
      return order;
    }
  }, [colorParam]);

  return (
    <div className={style.body}>
      <div style={{display: "flex"}}>
      <span className={style.title}>{dish.name}</span>
      {dish.sinTacc && 
                <img
                style={{
                  width: "40px",
                  marginRight:"auto"
                }}
                src={sinTaccIcon}
                alt=""
              />
      }
      </div>
      <hr style={{width: "100%", height: "2.5px", backgroundColor: "white"}}/>
      <div className={style.container}>
      <img className={style.img} src={dish.url} alt="" />
      <div
        className={style.text}
        dangerouslySetInnerHTML={{ __html: dish.editorData }}
      ></div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px"}}>
        {dish.vessel &&
          sortedVessels.map(
            (vessel) =>
              dish.vessel[vessel].checked && (
                <div>
                  <span className={style.price} >{vessel}: $ {dish.vessel[vessel].price}</span>
                </div>
              )
          )}
      </div>
    </div>
  );
}

export default DrinckModal;
