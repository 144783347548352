/* eslint-disable no-unused-vars */
import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore'
import { getStorage } from '@firebase/storage';
import { getAuth } from 'firebase/auth';
import 'firebase/storage'


const firebaseConfig = {
    apiKey: "AIzaSyCXIRkzh6aUBC5glFvuU0mlRhWcF4OtxyA" , 
    authDomain: "chimi-deli.firebaseapp.com",
    projectId: "chimi-deli",
    storageBucket: "chimi-deli.appspot.com",
    messagingSenderId: "336951267846",
    appId: "1:336951267846:web:c6432e5b8a53d01e09e987"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore()
const auth = getAuth(app);
export {storage, db, auth};

