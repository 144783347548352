/* eslint-disable no-unused-vars */
import logo from './logo.svg';
import {useState, useEffect} from 'react'
import './App.css';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import {db} from './config-firebase'
import ImageUpload from './component/imageUpload';
import FlowerLoader from './component/flowerLoader'
function App() {

  //mostrando los datps de la db en la ui
/*   const [menu, setMenu] = useState([])
  const menuCollectionRef = collection(db, "menu")
  useEffect(()=>{
    const getMenu = async()=>{
      const data = await getDocs(menuCollectionRef);
      setMenu(data.docs.map((doc)=>({...doc.data(), id: doc.id})))
    }

    getMenu();
  
  })

  //carga de platos
  const [newTitle, setNewTitle] = useState("");
  const [newPrice, setNewPrice] = useState(0);
  const [newDescription, setNewDescription] = useState("");
  const createPlate = async()=>{
    await addDoc(menuCollectionRef, {title: newTitle, price: newPrice, description: newDescription })
  }; */
  return (
   

    <div className="App">
      <FlowerLoader></FlowerLoader>
  {/*   <input placeholder='Titulo...' onChange={(event)=>{setNewTitle(event.target.value)}}></input>
    <input type="number" placeholder='Precio' onChange={(event)=>{setNewPrice(event.target.value)}}></input>
    <input placeholder='Descripcion' onChange={(event)=>{setNewDescription(event.target.value)}}></input>
    <button onClick={createPlate}>Cargar Plato</button>
      {menu.map((menu)=>{return <div>
        <h1>Nombre: {menu.title}</h1>
        <h1>Precio: ${menu.price}</h1>
        <h1>Descripcion: {menu.description}</h1>
        <h1>Ingredientes: {menu.ingredientes}</h1>
      </div> })}
      <ImageUpload></ImageUpload> */}
    </div>
  );
}

export default App;
