/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import loginstyle from "../styles/loginstyle.css";  // Importa los estilos personalizados para el componente de inicio de sesión
import { auth } from "../config-firebase";  // Importa la configuración de Firebase
import { GoogleAuthProvider, onAuthStateChanged, signOut, createUserWithEmailAndPassword } from 'firebase/auth';  // Importa los métodos de autenticación de Firebase
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../config-firebase'


function SignUp() {
    const navigate = useNavigate();
    const adminEmails = ["admideli1@gmail.com", "puducoop@gmail.com"]

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [newUser, setNewUser] = useState(null);



    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                if (adminEmails.includes(user.email)) {

                } else {
                    /* alert("No tenés permiso para ver este sitio.") */
                    navigate('/abm');
                }
            }
        })
    });


    const signupHandler = async (e) => {
        e.preventDefault();
        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const newUser = userCredential.user;
                setNewUser(newUser);
                alert("Usuario creado exitosamente. Se cerrará su sesión, por favor vuelva a ingresar");
                signOut(auth).then(() => {
                    navigate('/login');
                })

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                if (errorCode === "auth/email-already-in-use") {
                    alert("El correo electronico ya está en uso. Por favor intente nuevamente")
                } else {
                    alert("Error al crear un nuevo usuario. Por favor intente nuevamente");
                }

                // ..
            });
    }

    return (
        <div className="Style">
            <div className='signup-container'>
                <div className='login-box'>
                    <h1>CHIMI <span>DELI</span></h1>
                    <h2>COCINA NATURAL</h2>
                    <h3>Email:</h3><input type='email' placeholder='Email' value={email} className="login-input"
                        onChange={(e) => setEmail(e.target.value)}></input>
                    <h3>Contraseña:</h3><input type='password' placeholder='Contraseña' value={password} className="login-input"
                        onChange={(e) => setPassword(e.target.value)}></input>

                    <div className='login-btn-container'>
                        <button type='submit' className='login-btn' onClick={signupHandler}>Crear Usuario</button>
                        <a
                            target='blank'
                            href='https://console.firebase.google.com/u/3/project/chimi-deli/authentication/users?hl=es-419'
                        >
                            <button type='submit' className='login-btn'>
                                Administrar usuarios
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp