import React, { useState } from 'react';
import { updateDoc, doc } from "firebase/firestore";

function UpdatePricesDrinck({ menuList, setMenuList, menuCollectionRef }) {
  const [percentage, setPercentage] = useState('');

  const handleUpdatePrices = async () => {
    if (!percentage) {
      alert("Por favor, ingrese un porcentaje válido.");
      return;
    }

    const updatedMenuList = await Promise.all(menuList.map(async (item) => {
      const updatedVessels = {};
      let itemUpdated = false;
      item.vessel &&
      Object.keys(item.vessel).forEach((vesselKey) => {
        const vessel = item.vessel[vesselKey];
        if (vessel.checked && vessel.price) {
          itemUpdated = true;
          const newPrice = Math.round(Number(vessel.price) + Number(vessel.price * (percentage / 100)));
          updatedVessels[vesselKey] = {
            ...vessel,
            price: newPrice.toString(),
          };
        } else {
          updatedVessels[vesselKey] = vessel;
        }
      });

      if (itemUpdated) {
        const newItem = {
          ...item,
          vessel: updatedVessels,
        };

        const itemDoc = doc(menuCollectionRef, item.id);
        await updateDoc(itemDoc, { vessel: newItem.vessel });

        return newItem;
      }

      return item;
    }));

  setMenuList(updatedMenuList);
  setPercentage("");
  alert("Los precios se han actualizado correctamente.");
  };

  return (
    <div>
      <input
        type="number"
        value={percentage}
        onChange={(e) => setPercentage(e.target.value)}
        placeholder="Porcentaje de aumento"
      />
      <button onClick={handleUpdatePrices}>
        Actualizar Precios Bebidas
      </button>
    </div>
  );
}

export default UpdatePricesDrinck;